import React, {Dispatch, MutableRefObject, SetStateAction, useEffect, useLayoutEffect, useRef, useState,} from 'react';

import './uplotStyle.scss';
import UPlotReact from 'uplot-react';
import uPlot, {AlignedData, Series} from "uplot";
import {verticalAnnotationPlugin} from "./plugins/VerticalAnnotationPlugin";
import legendFormatterPlugin from "./plugins/LegendFormatterPlugin";
import {ChartData, HorizontalAnnotation} from "../../live-monitoring/generated-src";
import {DateRange} from "../chart-containers/ChartCollection";
import {Spinner, TextContent} from "@amzn/awsui-components-react";

const DEFAULT_CHART_WIDTH = 900;

const REVISION_BEFORE_PEAK_LABEL = "Last Scaling Guidance Prior to Peak"

export type ForecastChartProps = {
    chartData: ChartData | undefined;
    dateRange: DateRange
    dataLoading: boolean
    chartSync: uPlot.SyncPubSub
    triggerChartCallOnScaleChange: (resetToDefault: boolean, startTime?: Date, endTime?: Date) => void;
    mouseAction: MutableRefObject<{
        clickDown: boolean,
        moved: boolean,
        dateOfMouseDown: Date
    }>
}

export function mergeDataWithProps(props, data) {
    return {
        chartData: data,
        ...props
    }
}

export default function ForecastChart(props: ForecastChartProps) {
    if (props.chartData === undefined || props.chartData.datapoints === undefined || props.dataLoading) {
        return (<Spinner size="large"/>)
    }
    const parentRef = useRef<HTMLDivElement>(null)
    const mouseAction = props.mouseAction ? props.mouseAction : useRef({
        clickDown: false,
        moved: false,
        dateOfMouseDown: props.dateRange.minStart
    })

    const startTimeInSeconds = props.dateRange.currentStart.getTime() / 1000
    const endTimeInSeconds = props.dateRange.currentEnd.getTime() / 1000

    const [width, setWidth] = useState<number>(DEFAULT_CHART_WIDTH)
    useAdjustWidthByDivSize(parentRef, setWidth);

    const horizontalAnnotations = props.chartData.horizontalAnnotations
        .filter(a => a.startTime < endTimeInSeconds && a.endTime > startTimeInSeconds)
    let latestScalingGuidanceVersion = Number.MIN_VALUE
    horizontalAnnotations.forEach(annotation => {
        if (annotation.name.includes("Scaling Guidance") && annotation.version && annotation.version > latestScalingGuidanceVersion) {
            latestScalingGuidanceVersion = annotation.version
        }
    })


    const [seriesLabels, seriesDatapoints]: [uPlot.Series[], number[][]] =
        mapHorizontalAnnotationToSeries(
            horizontalAnnotations,
            props.chartData.datapoints?.dateDatapoints,
            props.chartData.inPeak,
            latestScalingGuidanceVersion)

    const makeStepFunctionLine = uPlot.paths.stepped?.({align: 1})

    const options: uPlot.Options = {
        width: width,
        height: 350,
        cursor: getCursorHandlers(props, mouseAction),
        scales: {'x': {min: startTimeInSeconds, max: endTimeInSeconds, range: [startTimeInSeconds, endTimeInSeconds]}},
        padding: [
            0,//top
            0, //right
            0, //bottom
            10], //left
        series: [
            {label: 'Date'},
            {
                label: 'p05',
                points: {show: false},
                stroke: '#ece0ed',
                value: formatToShortValue,
                paths: makeStepFunctionLine
            },
            {
                label: 'p50',
                points: {show: false},
                stroke: '#ece0ed',
                value: formatToShortValue,
                paths: makeStepFunctionLine
            },
            {
                label: 'p95',
                points: {show: false},
                stroke: '#eecfed',
                value: formatToShortValue,
                paths: makeStepFunctionLine
            },
            {
                label: 'actuals',
                points: {show: false},
                stroke: 'green',
                value: formatToShortValue,
                paths: makeStepFunctionLine
            },
            ...seriesLabels
        ],
        bands: [
            //Between p50 and p05
            {series: [2, 1], fill: "#ece0ed"},
            //Between p95 and p50
            {series: [3, 2], fill: "#eecfed"}
        ],
        axes: [
            {
                label: 'DateTime'
            },
            {
                values: (u, vals) => vals.map(val => formatToShortValue(u, val))
            }
        ],
        plugins: [verticalAnnotationPlugin(props.chartData?.verticalAnnotations), legendFormatterPlugin()]
    }

    const formattedData: AlignedData = [
        props.chartData.datapoints.dateDatapoints,
        props.chartData.datapoints.p05ValueDatapoints,
        props.chartData.datapoints.p50ValueDatapoints,
        props.chartData.datapoints.p95ValueDatapoints,
        props.chartData.datapoints.aDatapoints,
        ...seriesDatapoints
    ]


    return (
        <div ref={parentRef} graph-name={props.chartData.name}>
            <TextContent>
                <h1>{props.chartData.name}</h1>
                {formatGuidanceSummaryNumbers(
                    horizontalAnnotations,
                    latestScalingGuidanceVersion)}
            </TextContent>
            <UPlotReact
                key="hooks-key"
                options={options}
                data={formattedData}
                onCreate={uPlot => {
                    props.chartSync.sub(uPlot)
                }}
                onDelete={uPlot => props.chartSync.unsub(uPlot)}
            />
        </div>
    )
}

function mapHorizontalAnnotationToSeries(horizontalAnnotation: HorizontalAnnotation[],
                                         dateValues: number[],
                                         inPeak: boolean,
                                         latestScalingGuidanceVersion: number
): [Series[], number[][]] {
    const seriesMap = new Map()
    const minDate = dateValues[0]
    const maxDate = dateValues[dateValues.length - 1]

    //Retrieve scaling guidance annotations and group annotations by version
    const scalingGuidanceGroupedByVersion: Map<number, HorizontalAnnotation[]> = new Map()
    horizontalAnnotation.filter(ann => ann.name.includes("Scaling Guidance"))
        .forEach(
            ann => {
                if (!ann.version) {
                    ann.version = Number.MIN_VALUE
                }
                if (!scalingGuidanceGroupedByVersion.has(ann.version)) {
                    scalingGuidanceGroupedByVersion.set(ann.version, [])
                }
                scalingGuidanceGroupedByVersion.get(ann.version)?.push(ann)
            }
        )

    //label scaling guidance numbers
    const sortedVersion: number[] = [...scalingGuidanceGroupedByVersion.keys()]
        //Descending order sort since latest scaling guidance should always exist
        .sort((n1,n2) => n1 - n2 )

    //combine latest scaling guidance
    const latestScalingGuidanceAnnotations = scalingGuidanceGroupedByVersion.get(sortedVersion[0])
    let valArray = new Array(dateValues.length).fill(null);
    latestScalingGuidanceAnnotations?.forEach(annotation => {
        const startIdx = minDate > annotation.startTime ? 0 : dateValues.findIndex(val => val === annotation.startTime)
        const resolution = dateValues[1] - dateValues[0]
        const endTimeToMinute = Math.floor(annotation.endTime / resolution) * resolution
        const endIdx = maxDate < annotation.endTime ? dateValues.length - 1 : dateValues.findIndex(val => val === endTimeToMinute)
        valArray = valArray.fill(annotation.value, startIdx, endIdx + 1)
    })
    seriesMap.set("Latest Scaling Guidance", valArray)

    //Combine scaling guidance before peak
    if (inPeak && scalingGuidanceGroupedByVersion.size > 0) {
        const guidanceBeforePeak = scalingGuidanceGroupedByVersion.get(sortedVersion[1])
        valArray = new Array(dateValues.length).fill(null);
        guidanceBeforePeak?.forEach(annotation => {
            const startIdx = minDate > annotation.startTime ? 0 : dateValues.findIndex(val => val === annotation.startTime)
            const resolution = dateValues[1] - dateValues[0]
            const endTimeToMinute = Math.floor(annotation.endTime / resolution) * resolution
            const endIdx = maxDate < annotation.endTime ? dateValues.length - 1 : dateValues.findIndex(val => val === endTimeToMinute)
            valArray = valArray.fill(annotation.value, startIdx, endIdx + 1)
        })
        seriesMap.set(REVISION_BEFORE_PEAK_LABEL, valArray)
    }


    //Combine rest of annotations into series
    const nonScalingGuidanceAnnotations =
        horizontalAnnotation.filter(ann => !ann.name.includes("Scaling Guidance"))
    for (const annotation of nonScalingGuidanceAnnotations) {
        const name = annotation.name
        if (!seriesMap.has(name)) {
            seriesMap.set(name, new Array(dateValues.length).fill(null));
        }
        let valArray: (number | null)[] = seriesMap.get(name)
        const startIdx = minDate > annotation.startTime ? 0 : dateValues.findIndex(val => val === annotation.startTime)
        const resolution = dateValues[1] - dateValues[0]
        const endTimeToMinute = Math.floor(annotation.endTime / resolution) * resolution
        const endIdx = maxDate < annotation.endTime ? dateValues.length - 1 : dateValues.findIndex(val => val === endTimeToMinute)
        valArray = valArray.fill(annotation.value, startIdx, endIdx + 1)
        seriesMap.set(name, valArray)
    }

    const seriesLabels: uPlot.Series[] = []
    const seriesDatapoints: number[][] = []

    let colorIdx = 0
    for (const [label, datapoints] of seriesMap.entries()) {
        seriesLabels.push({
            label: label,
            stroke: chooseColor(label, inPeak, colorIdx),
            value: formatToShortValue,
            width: 2
        })
        seriesDatapoints.push(datapoints)
        colorIdx++;
    }

    return [seriesLabels, seriesDatapoints]

}

function chooseColor(label: any, inPeak: boolean, colorIdx: number) {
    if (label === "Game Day tested limit") {
        return "#B81D13"
    }

    if (label.includes("Scaling Guidance")) {
        if (inPeak && label === REVISION_BEFORE_PEAK_LABEL) {
            return "#688ae8"
        }
        return "#EFB700"
    }
    return "#2ea597"
}

function getCursorHandlers(props: ForecastChartProps, mouseDragStatus: React.MutableRefObject<{
    clickDown: boolean;
    moved: boolean;
    dateOfMouseDown: Date
}>) {
    return {
        sync:
            {
                key: props.chartSync.key,
                //Disable default date range sync functionality
                filters: {
                    pub: type => type !== 'mouseup'
                }
            },
        bind: {
            mousedown: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    mouseDragStatus.current.clickDown = true
                    if (self.cursor.idx) {
                        mouseDragStatus.current.dateOfMouseDown = new Date(self.data[0][self.cursor.idx] * 1000)
                    }
                    handler(e)
                    return null;
                }
            },
            mousemove: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    if (mouseDragStatus.current.clickDown) {
                        mouseDragStatus.current.moved = true
                    }
                    handler(e);
                    return null;
                }
            },
            mouseup: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return e => {
                    const dateOfMouseDown = mouseDragStatus.current.dateOfMouseDown;
                    if (mouseDragStatus.current.clickDown && mouseDragStatus.current.moved) {
                        if (mouseDragStatus.current.dateOfMouseDown && self.cursor.idx) {
                            const dateOfMouseUp = new Date(self.data[0][self.cursor.idx] * 1000)
                            const startDate = dateOfMouseDown < dateOfMouseUp ? dateOfMouseDown : dateOfMouseUp
                            const endDate = dateOfMouseDown < dateOfMouseUp ? dateOfMouseUp : dateOfMouseDown
                            props.triggerChartCallOnScaleChange(false, startDate, endDate)
                        }
                    }
                    mouseDragStatus.current = {
                        clickDown: false,
                        moved: false,
                        dateOfMouseDown: props.dateRange.minStart
                    }
                    handler(e);
                    return null;
                }
            },

            dblclick: (self: uPlot, targ: HTMLElement, handler: uPlot.Cursor.MouseListener) => {
                return () => {
                    props.triggerChartCallOnScaleChange(true)
                    return null;
                }
            }
        }
    };
}

function useAdjustWidthByDivSize(
    parentRef: React.RefObject<HTMLDivElement>,
    setWidth: Dispatch<SetStateAction<number>>
): void {
    // This effect is only needed for the first render to provide a synchronous update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(
        () => {
            const element = parentRef.current
            if (element) {
                new ResizeObserver(entries => {
                    // Prevent observe notifications on already unmounted component.
                    const width = entries[0].contentBoxSize[0].inlineSize
                    setWidth(prev => prev === width ? prev : width)
                });
            }
        },
        []
    );

    useEffect(() => {
            const element = parentRef.current
            if (element) {
                let connected = true;
                const observer = new ResizeObserver(entries => {
                    // Prevent observe notifications on already unmounted component.
                    if (connected) {
                        const width = entries[0].contentBoxSize[0].inlineSize
                        setWidth(prev => {
                            return prev === width ? prev : width
                        })
                    }

                })

                observer.observe(element);
                return () => {
                    connected = false;
                    observer.disconnect();
                };
            }
        },
        [parentRef]
    );
}

export function formatToShortValue(u: uPlot, rawValue: number) {
    if (rawValue >= 1_000_000_000) {
        return `${(rawValue / 1_000_000_000).toFixed(1)}B`
    } else if (rawValue >= 1_000_000) {
        return `${(rawValue / 1_000_000).toFixed(1)}M`
    } else if (rawValue >= 1000) {
        return `${(rawValue / 1000).toFixed(1)}K`
    }
    return rawValue ? rawValue.toFixed(2) : "--"
}


function formatGuidanceSummaryNumbers(annotations: HorizontalAnnotation[], maxVersionedScalingGuidance) {
    let scalingGuidanceAnnotations =
        annotations.filter(
            annotation => annotation.name.includes("Scaling Guidance") &&
                annotation.version === maxVersionedScalingGuidance)
    scalingGuidanceAnnotations.sort(compareStartDates)

    const nonScalingGuidanceAnnotations = annotations
        .filter(ann => !ann.name.includes("Scaling Guidance"))

    return [...scalingGuidanceAnnotations, ...nonScalingGuidanceAnnotations]
        .map(annotation => `${annotation.name}: ${Math.round(annotation.value).toLocaleString()}`)
        .join(' | ')
}

function compareStartDates(a: HorizontalAnnotation, b: HorizontalAnnotation): number {
    // prioritize the first annotation that occurs first
    if (a.startTime > b.startTime) {
        return 1;
    } else if (b.startTime > a.startTime) {
        return -1;
    }
    return 0;
}

function compareVersion(a: number | string, b: number | string): number {
    //Prioritize Existing version first
    if (typeof a === "number" && typeof b === "string") {
        return 1;
    } else if (typeof b === "number" && a === "string") {
        return -1
    }
    //prioritize latest version first
    else if (b > a) {
        return 1
    } else if (a > b) {
        return -1
    }
    return 0;
}

